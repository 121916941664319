import { NETWORK_TO_NUM } from "../utils/constants";

export const capitalize = ([first, ...rest]: string) =>
  first.toUpperCase() + rest.join("").toLowerCase();

export const isAcceptedNetwork = (network: number): boolean => {
  if (!process.env.REACT_APP_POLYGON_NETWORK) return false;

  return process.env.REACT_APP_POLYGON_NETWORK.split(",")
    .map((acceptedNetwork) => NETWORK_TO_NUM[acceptedNetwork])
    .includes(network);
};

export const capitalizeWords = (commaJoinedString: string) =>
  commaJoinedString
    .split(",")
    .map((word) => capitalize(word))
    .join(", ");

export function formatAccount(account: string): string {
  return `${account.substring(0, 2)}...${account.substring(
    account.length - 4
  )}`;
}
