import { Parallax } from "react-parallax";
import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import DiscordLogo from "../asset/icons/discord.svg";
import TwitterLogo from "../asset/icons/twitter.svg";
import FooterImage from "../asset/backgrounds/RecruitingPage.jpg";
import FooterImageMobile from "../asset/backgrounds/RecruitingPageMobile.png";
import Grid from "@mui/material/Grid";
import EmailSubscribe from "./EmailSubscribe";

const iconSize = 36;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minHeight: "60vh",
      padding: isMobile ? "50px 20px 50px 20px" : "80px 0px 100px 0px",
    },
    parallaxBg: {
      bottom: 0,
      filter: "brightness(90%) !important",
    },
    gridContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "27vw",
      maxWidth: "1800px",
      marginRight: "auto",
      marginLeft: "auto",
      position: "relative",
      flexWrap: "wrap",
    },
    row: {
      paddingTop: "1em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      textAlign: "center",
      width: "75px",
      height: "75px",
      cursor: "pointer",
      borderRadius: "50%",
      border: "5px solid #FF",
      transition: "all 0.5s ease",
      "&:hover": {
        transform: "scale(1.3)",
        transition: "all 0.5s ease",
      },
    },
    headingText: {
      fontFamily: "'Encode Sans SC', sans-serif",
      color: "#00A1F7",
      fontSize: "48px",
      fontWeight: 900,
      paddingBottom: "1em",
    },
    emailText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#00A1F7",
      fontSize: "20px",
      fontFamily: "'Catamaran', sans-serif",
      margin: "0 auto",
    },
    footer: {
      fontSize: isMobile ? "0.8em" : "1rem",
      color: "#ffffff",
      display: "flex",
      justifyContent: "center",
      paddingTop: isMobile ? "28em" : "28em",
      textAlign: "center",
      fontFamily: "'Roboto', sans-serif;",
      margin: "0 auto",
    },
  })
);

export default function Footer() {
  const classes = useStyles();

  return (
    <Parallax
      bgImage={isMobile ? FooterImageMobile : FooterImage}
      bgClassName={classes.parallaxBg}
      bgImageAlt="footer background"
      strength={0}
    >
      <div className={classes.container}>
        <div className={classes.gridContainer}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.row}>
                <div className={classes.footer}>
                  © 2024 Anykraft Pte. Ltd. All Rights Reserved.
                  <br></br>
                  support@anykraft.io
                </div>
              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <div className={classes.row}>
                <div className={classes.headingText}>SUBSCRIBE</div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <EmailSubscribe />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.row}>
                <Link
                  className={classes.icon}
                  to={{ pathname: "https://discord.gg/XfHTMn69Yw" }}
                  target="_blank"
                >
                  <img src={DiscordLogo} width={iconSize} alt="discord-logo" />
                </Link>
                <Link
                  className={classes.icon}
                  to={{ pathname: "https://twitter.com/Anykraft" }}
                  target="_blank"
                >
                  <img src={TwitterLogo} width={iconSize} alt="twitter-logo" />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.emailText}>admin@anykraft.io</div>
            </Grid>*/}
          </Grid>
        </div>
      </div>
    </Parallax>
  );
}
