import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/utils";
import LandingPageBackground from "../asset/backgrounds/NeonRunnersCover.png";
import LandingPageMobileBackground from "../asset/backgrounds/NeonRunnersCover.png";
import { BAR_HEIGHT } from "./MainAppBar";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    container: {
      background: `url(${
        isMobile ? LandingPageMobileBackground : LandingPageBackground
      })`,
      // background: `url(${
      //   isMobile ? LandingPageMobileBackground : LandingPageBackground
      // })`,
      marginTop: BAR_HEIGHT,
      height: isMobile ? "50vh" : `calc(100vh - ${BAR_HEIGHT}px)`,
      width: "auto",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: isMobile ? "cover" : "contain",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    explore: {
      position: "relative",
      display: "flex",
      marginTop: "auto",
      marginBottom: "3em",
      width: "100%",
      justifyContent: "center",
    },
    title: {
      whiteSpace: "nowrap",
      marginTop: "100px",
      textShadow:
        "-1px -1px 5px #fff, 1px -1px 5px #fff, -1px 1px 5px #fff, 1px 1px 5px #fff",
      color: "#00A1F7",
      fontStyle: "italic",
      fontSize: isMobile ? "1.2em" : "2rem",
    },
    subtitle: {
      marginBottom: "0.5em",
      fontSize: isMobile ? "1em" : "1.4rem",
    },
    footer: {
      fontSize: isMobile ? "0.8em" : "1rem",
      fontStyle: "italic",
      textShadow:
        "-1px -1px 5px #fff, 1px -1px 5px #fff, -1px 1px 5px #fff, 1px 1px 5px #fff",
      color: "#00A1F7",
    },
    text: {
      color: "#fff",
      background: "#61CE7000",
      fontFamily: "'Roboto', sans-serif;",
      margin: isMobile ? "0" : "0% 35% 0% 35%",
      padding: "0 0.5em",
      letterSpacing: 2.2,
      position: "relative",
      fontWeight: 900,
      textAlign: "center",
      textShadow: "0px 2px 15px rgba(0, 0, 0, 1)",
    },
    "@keyframes swing": {
      "0%": {
        transform: "translateY(0)",
      },
      "50%": {
        transform: "translateY(30%)",
      },
      "100%": {
        transform: "translateY(0)",
      },
    },
  })
);

export default function LandingPage() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.explore}>
        <div className={classes.text}>
          {/*          <div className={classes.title}>PLAY + BUILD + SHARE</div>
          <div className={classes.subtitle}></div>
          <div className={classes.footer}>
            MOBILE FIRST FREE TO PLAY PvP + UGC BUILT{" "}
            <span style={{ whiteSpace: "nowrap" }}>BY THE KRAFTERS</span>{" "}
          </div>*/}
        </div>
      </div>
    </div>
  );
}
