import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { isMobile } from "react-device-detect";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MetaMaskButton from "../components/MetaMaskButton";
import AnykraftLogo from "../asset/logos/anykraft_logo_2023.png";
import NeonRunnersLogo from "../asset/logos/neon_runners.png";
import NeonRunnersLogo2 from "../asset/logos/neon_runners2.png";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/utils";

export const BAR_HEIGHT = 77;

type StyleProps = {
  trigger: boolean;
};

type MainAppBarProps = {
  setLoginStorage?: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    shortcutItem: {
      fontFamily: "'Encode Sans SC', sans-serif",
      color: "#ffffff",
      fontSize: isMobile ? 15 : 20,
      textTransform: "uppercase",
      padding: "0em 1em",
      "&:hover": {
        color: "#00A1F7",
        transition: "0.5s ease",
      },
    },
    transparentBackground: {
      background: "black !important",
      // background: `linear-gradient( rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) )`,
      display: "flex",
      boxShadow: "none !important",
      height: BAR_HEIGHT,
      verticalAlign: "middle",
    },
  })
);

export default function MainAppBar({ setLoginStorage }: MainAppBarProps) {
  const [trigger, setTrigger] = useState<boolean>(true);
  const classes = useStyles({ trigger });

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.documentElement.scrollTop > 199 ||
        document.body.scrollTop > 199
      ) {
        setTrigger(false);
      } else {
        setTrigger(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar className={classes.transparentBackground}>
      <Container maxWidth={isMobile ? "sm" : "xl"} sx={{ margin: "auto" }}>
        <Toolbar disableGutters>
          <Box sx={{ display: "flex" }}>
            <Button href="/#home">
              <img
                src={AnykraftLogo}
                width={isMobile ? 70 : 70}
                alt="anykraft-logo"
              />
            </Button>
            {/* <MetaMaskButton
              setLoginStorage={setLoginStorage}
              inverted={trigger}
            /> */}
          </Box>
          <Box sx={{ flexGrow: 5 }} />
          {!isMobile && (
            <>
              {/* <Box sx={{ display: "flex" }}>
                <Button key={"Land"} href="#land" sx={{ display: "block" }}>
                  <div className={classes.shortcutItem}>Land</div>
                </Button>
              </Box> 
              <Box sx={{ display: "flex" }}>
                <Button key={"Demo"} href="/#demo" sx={{ display: "block" }}>
                  <div className={classes.shortcutItem}>Demo</div>
                </Button>
              </Box> */}
              <Box sx={{ display: "flex" }}>
                <Button href="https://neonrunners.gg/">
                  <img
                    src={NeonRunnersLogo}
                    width={isMobile ? 70 : 70}
                    alt="neonrunners-logo"
                  />
                </Button>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button key={"Team"} href="/#team" sx={{ display: "block" }}>
                  <div className={classes.shortcutItem}>Team</div>
                </Button>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  key={"Team"}
                  href="/#partners"
                  sx={{ display: "block" }}
                >
                  <div className={classes.shortcutItem}>Investors</div>
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
