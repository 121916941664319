import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { ethers, Contract } from "ethers";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MetaMaskOnboarding from "@metamask/onboarding";
import Layout from "./components/Layout";
import { EthereumContext } from "./components/EthereumContext";
import Home from "./containers/Home";
import PolymonContract from "./shared/PolymonContract.json";
import RegistrationContract from "./shared/Registration.json";
import useFetchCurrentChain from "./components/useFetchCurrentChain";
import { capitalizeWords, isAcceptedNetwork } from "./utils/helper";
import useLocalStorage from "./components/useLocalStorage";
import { openNotificationWithIcon } from "./utils/openNotification";
import Loading from "./components/Loading";
import DefendersOfAnykraft from "./containers/DefendersOfAnykraft";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

const TRACKING_ID = "UA-229352744-2";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [account, setAccount] = useState<string | undefined>();
  const [polymonContract, setPolymonContract] = useState<Contract | undefined>(
    undefined
  );
  const [registrationContract, setRegistrationContract] = useState<
    Contract | undefined
  >(undefined);
  const [loginStorage, setLoginStorage] = useLocalStorage<boolean>(
    "polymon_io_login",
    null
  );
  const { currentChain, currentChainLoading } = useFetchCurrentChain();
  const [blockchainContractLoading, setBlockchainContractLoading] =
    useState<boolean>(false);

  const handleAccountChange = async () => {
    setBlockchainContractLoading(true);
    new Promise((resolve) =>
      resolve(window.ethereum.request({ method: "eth_requestAccounts" }))
    )
      .then(() => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const newPolymonContract = new Contract(
          PolymonContract.address,
          PolymonContract.abi,
          signer
        );
        const newRegistrationContract = new Contract(
          RegistrationContract.address,
          RegistrationContract.abi,
          signer
        );

        setPolymonContract(newPolymonContract);
        setRegistrationContract(newRegistrationContract);
      })
      .finally(() => setBlockchainContractLoading(false));
  };

  // Google Analytics
  useEffect(() => {
    if (window.location.hostname === "anykraft.io") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const handleLogout = () => {
    window.localStorage.removeItem("polymon_io_login");
    setAccount(undefined);
    setPolymonContract(undefined);
    setRegistrationContract(undefined);
  };

  useEffect(() => {
    if (loginStorage) {
      if (isAcceptedNetwork(Number(currentChain))) {
        if (window.ethereum.selectedAddress) {
          setAccount(window.ethereum.selectedAddress);
          handleAccountChange();
        }
      } else if (currentChain) {
        openNotificationWithIcon(
          "error",
          `Please change the network to one of ${capitalizeWords(
            process.env.REACT_APP_POLYGON_NETWORK
          )}.`,
          `We use ${capitalizeWords(
            process.env.REACT_APP_POLYGON_NETWORK
          )} to record data about your characters. Click 'Connect' button to add Matic Network into your Metamask Extension.`
        );
      }
    }
  }, [currentChain]);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", (newAccounts) => {
        if (!newAccounts.length) {
          handleLogout();
        } else {
          setAccount(newAccounts[0]);
        }
      });
      window.ethereum.on("chainChanged", () => window.location.reload());

      return () => {
        if (window.ethereum.off) {
          window.ethereum.off("accountsChanged", (newAccounts) => {
            if (!newAccounts.length) {
              handleLogout();
            } else {
              setAccount(newAccounts[0]);
            }
          });
          window.ethereum.off("chainChanged", () => window.location.reload());
        }
      };
    }
  }, []);

  const GAME_ENABLED = process.env.REACT_APP_GAME_ENABLED;
  const blockChainLoading = currentChainLoading || blockchainContractLoading;

  return (
    <EthereumContext.Provider
      value={{
        account,
        setAccount,
        polymonContract,
        registrationContract,
        handleAccountChange,
        handleLogout,
      }}
    >
      <BrowserRouter>
        <Layout setLoginStorage={setLoginStorage}>
          {blockChainLoading ? (
            <Loading size={"large"} />
          ) : (
            <Switch>
              {GAME_ENABLED === "true" && (
                <Route exact path="/game" component={DefendersOfAnykraft} />
              )}
              <Route exact path="/" component={Home} />
              <Redirect from="/*" to="/" />
            </Switch>
          )}
        </Layout>
      </BrowserRouter>
    </EthereumContext.Provider>
  );
}

export default App;
