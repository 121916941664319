import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/utils";

type StyleProps = {
  bgcolor: string;
  progress: number;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    container: {
      height: 20,
      width: "100%",
      backgroundColor: "white", // "#333232",
      borderRadius: 50,
      border: "2px solid white",
    },
    filler: {
      height: "100%",
      borderRadius: "inherit",
      align: "right",
      width: ({ progress }) => `${progress}%`,
      backgroundColor: ({ bgcolor }) => bgcolor,
      // transition: "width 0.1s ease-in-out",
    },
    label: {
      padding: 5,
      color: "black",
      fontWeight: "bold",
    },
  })
);

export default function ProgressBar({
  bgcolor,
  progress,
}: {
  bgcolor: string;
  progress: number;
}) {
  const classes = useStyles({ bgcolor, progress });

  return (
    <div className={classes.container}>
      <div className={classes.filler}>
        <span className={classes.label}>{`${progress.toFixed(0)}%`}</span>
      </div>
    </div>
  );
}
