/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from "react";
import { Contract } from "ethers";

export type EthereumContent = {
  account?: string;
  setAccount: (c?: string) => void;
  polymonContract?: Contract;
  registrationContract?: Contract;
  handleAccountChange: () => void;
  handleLogout: () => void;
};

export const EthereumContext = createContext<EthereumContent>({
  account: undefined,
  setAccount: () => {},
  polymonContract: undefined,
  registrationContract: undefined,
  handleAccountChange: () => {},
  handleLogout: () => {},
});

export const useEthereumContext = () => useContext(EthereumContext);
