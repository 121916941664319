import { useState, useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

export default function useFetchCurrentChain() {
  const [currentChainLoading, setCurrentChainLoading] = useState<boolean>(true);
  const [currentChain, setCurrentChain] = useState<string>();

  useEffect(() => {
    let fresh = true;
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      new Promise((resolve) => {
        resolve(window.ethereum.request({ method: "eth_chainId" }));
      })
        .then((chainId: string) => {
          if (fresh) setCurrentChain(chainId);
        })
        .finally(() => {
          if (fresh) setCurrentChainLoading(false);
        });

      return () => {
        fresh = false;
      };
    } else {
      setCurrentChainLoading(false);
    }
  }, []);

  return {
    currentChainLoading,
    currentChain,
  };
}
