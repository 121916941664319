import { ReactNode, Dispatch, SetStateAction } from "react";
import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import MainAppBar from "./MainAppBar";
import Footer from "./Footer";
import FooterImage from "../asset/decorations/FooterImage.png";
import DiscordLogo from "../asset/logos/Discord.png";

type LayoutProps = {
  children: ReactNode;
  setLoginStorage?: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles(() =>
  createStyles({
    headerWeb: {
      position: "relative",
      zIndex: 198,
    },
    headerMobile: {
      position: "relative",
      zIndex: 198,
    },
    content: {
      // height: "100%",
      // position: "relative",
      zIndex: 197,
    },
    container: {
      backgroundColor: "black",
    },
    discordWidgetMobile: {
      display: "block",
      position: "fixed",
      right: 0,
      bottom: 20,
    },
    discordWidget: {
      zIndex: 199,
      position: "fixed",
      bottom: 30,
      right: 30,
      transition: ".2s ease",
      backgroundColor: "#5865F2",
      width: 70,
      height: 70,
      borderRadius: 35,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default function Layout({ children, setLoginStorage }: LayoutProps) {
  const classes = useStyles();
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <a
        className={classes.discordWidget}
        target="_blank"
        href="https://discord.gg/XfHTMn69Yw"
      >
        <img src={DiscordLogo} width={45} />
      </a> */}
      <MainAppBar setLoginStorage={setLoginStorage} />
      {children}
      <Footer />
    </div>
  );
}
