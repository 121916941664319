import AnykraftWorld from "../components/AnykraftGlobe/AnykraftWorld";
import Demo from "../components/Demo";
import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/utils";
import Partners from "../components/Partners";
import Team from "../components/Team";
import Land from "../components/Land";
import PFP from "../components/PFP";
import LandSale from "../components/LandSale";
import Faq from "../components/Faq";
import LandingPage from "../components/LandingPage";
import AnykraftGlobe from "../components/AnykraftGlobe";
import { BACKGROUND_BLACK } from "../utils/constants";
import Careers from "../components/Careers";

type styleProps = {
  isMobile?: boolean;
};

const useStyles = makeStyles<Theme, styleProps>(() =>
  createStyles({
    container: {
      backgroundColor: "black",
    },
    bodyContainer: {
      background: BACKGROUND_BLACK,
      width: "100vw",
      // height: ({ isMobile }) => (isMobile ? "70vh" : "100vh"),
      // backgroundPosition: "center",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "cover",
      // display: "block",
      // padding: ({ isMobile }) =>
      //   isMobile ? "50px 20px 50px 20px" : "10em 0em 15em 0em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      margin: "0 auto",
    },
  })
);

export default function Home() {
  const classes = useStyles({ isMobile: isMobile });

  return (
    <div className={classes.container}>
      <section id="home">
        <LandingPage />
      </section>
      {/*<section id="demo">
        <Demo />
      </section>*/}
      {/*      <section id="world">
        <AnykraftWorld />
      </section>
      ;*/}
      {/*</section>
       <section id="land">
        <Land />
      </section> 
      <section id="pfp">
        <PFP />
      </section>
       <section id="landsale">
        <LandSale />
      </section> */}
      <section id="team">
        <Team />
      </section>
      <section id="partners">
        <Partners />
      </section>
      {/*      <section id="faq">
        <Faq />
      </section>*/}
      {/*      <section id="jobs">
        <Careers />
      </section>*/}
    </div>
  );
}
