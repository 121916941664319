import { isMobile } from "react-device-detect";

export const NETWORK_TO_NUM: { [key: string]: number } = {
  matic: 137,
  mumbai: 80001,
  localhost: 31337,
  rinkeby: 4,
};

export const MAX_WIDTH = 1000;
export const WINDOW_WIDTH_THRESHOLD = 1300;

export type Polymon = {
  id: string;
  tokenUri: string;
  dna: string;
  ancestorUris: string[];
  level: number;
  experience: number;
  experienceToEvolve: number;
  feedCooldownStartTimestamp: number;
  feedCooldownEndTimestamp: number;
};

export const SECTION_MAX_WIDTH = 1300;
export const SECTION_PADDING = isMobile
  ? "5em 20px 5em 20px"
  : "8em 0px 10em 0px";

export const BACKGROUND_BLACK = "#000000";
export const BACKGROUND_GRAY = "#0f0f0f";
export const BACKGROUND_WHITE = "#ffffff";
