import { createStyles, makeStyles } from "@material-ui/styles";
import { Spin } from "antd";
import { SpinSize } from "antd/lib/spin";

type LayoutProps = {
  size: SpinSize;
};

const useStyles = makeStyles(() =>
  createStyles({
    spin: {
      textAlign: "center",
      height: "100%",
      margin: "15%",
    },
  })
);

export default function Loading({ size }: LayoutProps) {
  const classes = useStyles();

  return (
    <div className={classes.spin}>
      <Spin size={size} />
    </div>
  );
}
