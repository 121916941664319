import { useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { isMobile } from "react-device-detect";
import { createStyles, makeStyles } from "@material-ui/styles";
import ProgressBar from "../components/ProgressBar";
import AnykraftLogo from "../asset/logos/AnykraftLogo2.png";
import GameInstructions from "../asset/backgrounds/GameInstructions.png";

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      backgroundColor: "black",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    container: {
      display: "flex",
      margin: "auto",
      flexDirection: "column",
      alignItems: "center",
    },
    unity: {
      display: "flex",
      marginTop: 80,
      margin: "auto 0",
      justifyContent: "center",
      alignItems: "center",
      height: 534,
      width: 950,
      border: "2px solid black",
      backgroundImage: `url(${AnykraftLogo})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    loadingBar: {
      display: "flex",
      margin: "8px auto",
      width: "300px",
      height: "20px",
      "text-align": "right",
    },
    instructions: { maxWidth: 700 },
    mobileDisclaimer: {
      paddingBottom: "1em",
      fontFamily: "'Encode Sans SC', sans-serif",
      color: "#FFFFFF",
      fontSize: "24px",
      fontWeight: 900,
      padding: "0 1em",
      textAlign: "center",
    },
  })
);

const unityContext = new UnityContext({
  loaderUrl: "buildUnity/WebGL.loader.js",
  dataUrl: "buildUnity/WebGL.data",
  frameworkUrl: "buildUnity/WebGL.framework.js",
  codeUrl: "buildUnity/WebGL.wasm",
});

export default function DefendersOfAnykraft() {
  const classes = useStyles();
  const minProgress = 10 / 100;
  const [progress, setProgress] = useState(minProgress);

  useEffect(function () {
    unityContext.on("progress", function (_progress) {
      setProgress(Math.max(minProgress, _progress));
    });
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        {isMobile ? (
          <div className={classes.mobileDisclaimer}>
            Mobile devices are not supported at this time. Please switch to
            desktop to play our demo.
          </div>
        ) : (
          <>
            <Unity className={classes.unity} unityContext={unityContext} />
            {progress === 1 ? (
              <img className={classes.instructions} src={GameInstructions} />
            ) : (
              <div id="unity-loading-bar" className={classes.loadingBar}>
                <ProgressBar bgcolor="#d1b369" progress={progress * 100} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
